// src/pages/Home.js
import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import Carousel from './Home/Carousel';
import TopFeatures from './Home/TopFeatures';
import EventsSeg from './Events/EventsSeg';
import AboutSeg from './About/AboutSeg';
import FacilitiesSeg from './Facilities/FacilitiesSeg';
import Members from './About/Members';
import Testimonial from './Testimonials/Testimonial';

const Home = () => {
    return (
        <>
            <Container maxWidth={false} disableGutters>
                <Carousel />
            </Container>
            <Container>
                {/* Features Section */}
                <TopFeatures />

            </Container>
            <AboutSeg />
            <FacilitiesSeg />
            <EventsSeg />
            <Members />
            <Testimonial />
        </>
    );
};

export default Home;
