import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import WOW from 'wowjs';
import theme from './styles/Theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import History from './pages/About/History';
import Facilities from './pages/Facilities';
import Events from './pages/Events';

const App = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // Disable reinitializing animations on dynamic content change
    });
    wow.init(); // Initialize WOW.js once when the app mounts
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Resets browser defaults and applies global theme */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />}>
            <Route path="history" element={<History />} />
          </Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/events" element={<Events />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
