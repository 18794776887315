import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importing images
import testiminialImage1 from '../../assets/images/testimonial-1.jpg';
import testiminialImage2 from '../../assets/images/testimonial-2.jpg';

const Testimonial = () => {

    // Custom Next Arrow
    const NextArrow = ({ className, onClick }) => (
        <div className={className} onClick={onClick}>
            <i className="fa-solid fa-arrow-right" />
        </div>
    );

    // Custom Previous Arrow
    const PrevArrow = ({ className, onClick }) => (
        <div className={className} onClick={onClick}>
            <i className="fa-solid fa-arrow-left" />
        </div>
    );

    const settings = {
        dots: true,             // Enable dots for navigation
        infinite: true,         // Infinite loop
        speed: 500,             // Transition speed
        slidesToShow: 1,        // Number of slides to show at once
        slidesToScroll: 1,      // Number of slides to scroll at a time
        autoplay: true,         // Enable autoplay
        arrows: true,           // Enable arrows (Next/Previous)
        nextArrow: <NextArrow />, // Custom Next Arrow component
        prevArrow: <PrevArrow />, // Custom Previous Arrow component
    };

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div
                        className="col-lg-5 wow animate__fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        <p className="fs-5 fw-bold text-primary">Testimonial</p>
                        <h1 className="display-5 mb-5">What Our Clients Say About Us!</h1>
                        <p className="mb-4">
                            Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                            Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit sed
                            stet lorem sit clita duo justo.
                        </p>
                        <a className="btn btn-primary py-3 px-4" href="">
                            See More
                        </a>
                    </div>
                    <div
                        className="col-lg-7 wow animate__fadeInUp"
                        data-wow-delay="0.5s"
                    >
                        <Slider {...settings}>
                            <div className="testimonial-item">
                                <img
                                    className="img-fluid rounded mb-3"
                                    src={testiminialImage1}
                                    alt="Client 1"
                                />
                                <p className="fs-5">
                                    Dolores sed duo clita tempor justo dolor et stet lorem kasd
                                    labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                                    et labore et tempor diam tempor erat.
                                </p>
                                <h4>Client Name</h4>
                                <span>Profession</span>
                            </div>
                            <div className="testimonial-item">
                                <img
                                    className="img-fluid rounded mb-3"
                                    src={testiminialImage2}
                                    alt="Client 2"
                                />
                                <p className="fs-5">
                                    Dolores sed duo clita tempor justo dolor et stet lorem kasd
                                    labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                                    et labore et tempor diam tempor erat.
                                </p>
                                <h4>Client Name</h4>
                                <span>Profession</span>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
