// src/theme.js
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: '"Jost", system-ui, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontOpticalSizing: 'auto',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                },
            },
        },
    },
});

export default theme;
