import React, { useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb';

const FacilitiesSeg = () => {

    // Service data (this could be fetched from an API instead)
    const services = [
        {
            id: 1,
            title: 'Landscaping',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-1.jpg',
            icon: '/images/icon/icon-3.png',
            delay: '0.1s',
        },
        {
            id: 2,
            title: 'Pruning Plants',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-2.jpg',
            icon: '/images/icon/icon-6.png',
            delay: '0.3s',
        },
        {
            id: 3,
            title: 'Irrigation & Drainage',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-3.jpg',
            icon: '/images/icon/icon-5.png',
            delay: '0.5s',
        },
        {
            id: 4,
            title: 'Garden Maintenance',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-4.jpg',
            icon: '/images/icon/icon-4.png',
            delay: '0.1s',
        },
        {
            id: 5,
            title: 'Green Technology',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-5.jpg',
            icon: '/images/icon/icon-8.png',
            delay: '0.3s',
        },
        {
            id: 6,
            title: 'Urban Gardening',
            description:
                'Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.',
            img: '/images/service-6.jpg',
            icon: '/images/icon/icon-2.png',
            delay: '0.5s',
        },
    ];

    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    {/* Header Section */}
                    <div
                        className="text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{ maxWidth: '500px' }}
                    >
                        <p className="fs-5 fw-bold text-primary">Our Facilities</p>
                        <h1 className="display-5 mb-5">Facilities That We Offer For You</h1>
                    </div>

                    {/* Service Items */}
                    <div className="row g-4">
                        {services.map((service) => (
                            <div
                                key={service.id}
                                className="col-lg-4 col-md-6 wow fadeInUp"
                                data-wow-delay={service.delay}
                            >
                                <div className="service-item rounded d-flex h-100">
                                    <div className="service-img rounded">
                                        <img className="img-fluid" src={service.img} alt={service.title} />
                                    </div>
                                    <div className="service-text rounded p-5">
                                        <div className="btn-square rounded-circle mx-auto mb-3">
                                            <img className="img-fluid" src={service.icon} alt="Icon" />
                                        </div>
                                        <h4 className="mb-3">{service.title}</h4>
                                        <p className="mb-4">{service.description}</p>
                                        <a className="btn btn-sm" href="#">
                                            <i className="fa fa-plus text-primary me-2"></i>Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FacilitiesSeg;
