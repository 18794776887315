import React from 'react';

const Topbar = () => {
    return (
        <div className="container-fluid bg-dark text-light px-0 py-2">
            <div className="row gx-0 d-none d-lg-flex">
                <div className="col-lg-7 px-5 text-start">
                    <div className="h-100 d-inline-flex align-items-center me-4">
                        <span className="fa fa-phone-alt me-2"></span>
                        <span>+012 345 6789</span>
                    </div>
                    <div className="h-100 d-inline-flex align-items-center">
                        <span className="far fa-envelope me-2"></span>
                        <span>info@example.com</span>
                    </div>
                </div>
                <div className="col-lg-5 px-5 text-end">
                    <div className="h-100 d-inline-flex align-items-center mx-n2">
                        <span>Follow Us:</span>
                        <a className="btn btn-link text-light" href="#"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-link text-light" href="#"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-link text-light" href="#"><i className="fab fa-linkedin-in"></i></a>
                        <a className="btn btn-link text-light" href="#"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Topbar;

// src/components/Topbar.js
// import React from 'react';
// import { Box, Typography, Container, Grid, Link } from '@mui/material';
// import { Phone, Email } from '@mui/icons-material';
// import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';

// const Topbar = () => {
//     return (
//         <Box sx={{ bgcolor: 'dark', color: 'light', py: 1 }}>
//             <Container maxWidth="lg">
//                 <Grid container spacing={2}>
//                     <Grid item xs={12} lg={7} display="flex" justifyContent="flex-start">
//                         <Box display="flex" alignItems="center" mr={4}>
//                             <Phone fontSize="small" sx={{ mr: 1 }} />
//                             <Typography variant="body2">+012 345 6789</Typography>
//                         </Box>
//                         <Box display="flex" alignItems="center">
//                             <Email fontSize="small" sx={{ mr: 1 }} />
//                             <Typography variant="body2">info@example.com</Typography>
//                         </Box>
//                     </Grid>
//                     <Grid item xs={12} lg={5} display="flex" justifyContent="flex-end">
//                         <Typography variant="body2" sx={{ mr: 2 }}>Follow Us:</Typography>
//                         <Link href="#" color="inherit" sx={{ mx: 1 }}><Facebook /></Link>
//                         <Link href="#" color="inherit" sx={{ mx: 1 }}><Twitter /></Link>
//                         <Link href="#" color="inherit" sx={{ mx: 1 }}><LinkedIn /></Link>
//                         <Link href="#" color="inherit" sx={{ mx: 1 }}><Instagram /></Link>
//                     </Grid>
//                 </Grid>
//             </Container>
//         </Box>
//     );
// };

// export default Topbar;

// src/components/Topbar.js

