import React from 'react';
import Breadcrumb from '../components/Breadcrumb';

const Contact = () => {
    const breadcrumbs = [
        { label: 'Home', path: '/', active: false },
        { label: 'Contact', path: '/contact', active: true },
    ];

    return (
        <div>
            <Breadcrumb title="Contact Us" breadcrumbs={breadcrumbs} />
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        {/* Contact Form Section */}
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <p className="fs-5 fw-bold text-primary">Contact Us</p>
                            <h1 className="display-5 mb-5">If You Have Any Query, Please Contact Us</h1>
                            <p className="mb-4">
                                The contact form is currently inactive. Get a functional and working contact form
                                with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code
                                and you're done. <a href="https://artechniq.co.in/contact-form">Download Now</a>.
                            </p>
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Your Name"
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Your Email"
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="subject"
                                                placeholder="Subject"
                                            />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Leave a message here"
                                                id="message"
                                                style={{ height: "100px" }}
                                            ></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary py-3 px-4" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* Google Map Section */}
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: "450px" }}>
                            <div className="position-relative rounded overflow-hidden h-100">
                                <iframe
                                    className="position-relative w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2244.0307547771117!2d77.75083969333016!3d13.02830983028223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1233ff085a67%3A0xe6fde7b9dd6f5bf6!2sSumadhura%20Eden%20Garden!5e1!3m2!1sen!2sin!4v1726219978968!5m2!1sen!2sin"
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                    frameBorder="0"
                                    style={{ minHeight: "450px", border: 0 }}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

