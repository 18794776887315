// src/components/Carousel.js
import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importing images
import carouselImage1 from '../../assets/images/carousel-1.jpg';
import carouselImage2 from '../../assets/images/carousel-2.jpg';

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Slider {...settings}>
                {/* Slide 1 */}
                <Box sx={{ position: 'relative', height: '600px' }}>
                    <img src={carouselImage1} alt="1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Container>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h2" color="white" gutterBottom>Make Your Home Like Garden</Typography>
                                    {/* <Button variant="contained" color="primary">Explore More</Button> */}
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>

                {/* Slide 2 */}
                <Box sx={{ position: 'relative', height: '600px' }}>
                    <img src={carouselImage2} alt="2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Container>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h2" color="white" gutterBottom>Create Your Own Small Garden At Home</Typography>
                                    {/* <Button variant="contained" color="primary">Explore More</Button> */}
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </Slider>
        </Box>
    );
};

export default Carousel;
