import React, { useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FacilitiesSeg from './Facilities/FacilitiesSeg';

const Facilities = () => {
    const breadcrumbs = [
        { label: 'Home', path: '/', active: false },
        { label: 'Facilities', path: '/facilities', active: true },
    ];

    // Service data (this could be fetched from an API instead)

    return (
        <div>
            <Breadcrumb title="Our Facilities" breadcrumbs={breadcrumbs} />
            <FacilitiesSeg />
        </div>

    );
};

export default Facilities;
