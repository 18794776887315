// src/components/Header.js
import React from 'react';
import Topbar from './Header/Topbar';
import Navbar from './Header/Navbar'

const Header = () => {
    return (
        <>
            <Topbar />
            <Navbar />
        </>
    );
};

export default Header;
