import { useEffect } from 'react';
import React from 'react';
import aboutImage from '../../assets/images/about.jpg';  // Update with the correct path to the image
import carouselImage from '../../assets/images/carousel-1.jpg'; // Update with the correct path to the image

const AboutSeg = () => {
    useEffect(() => {
        const parallaxElement = document.querySelector('.parallax');
        if (parallaxElement) {
            const imageUrl = parallaxElement.getAttribute('data-image-src');
            parallaxElement.style.backgroundImage = `linear-gradient(rgba(15, 66, 41, 0.6), rgba(15, 66, 41, 0.6)), url(${imageUrl})`;
            parallaxElement.style.backgroundAttachment = 'fixed';
            parallaxElement.style.backgroundSize = 'cover';
            parallaxElement.style.backgroundPosition = 'center';
        }
    }, []);
    return (
        <div>
            {/* About Section Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-end">
                        <div className="col-lg-3 col-md-5 wow fadeInUp" data-wow-delay="0.1s">
                            <img className="img-fluid rounded" src={aboutImage} alt="About Us" />
                        </div>
                        <div className="col-lg-6 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
                            <h1 className="display-1 text-primary mb-0">25</h1>
                            <p className="text-primary mb-4">Year of Experience</p>
                            <h1 className="display-5 mb-4">We Make Your Home Like A Garden</h1>
                            <p className="mb-4">
                                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.
                                Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet.
                            </p>
                            <a className="btn btn-primary py-3 px-4" href="#explore">Explore More</a>
                        </div>
                        <div className="col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="row g-5">
                                <div className="col-12 col-sm-6 col-lg-12">
                                    <div className="border-start ps-4">
                                        <i className="fa fa-award fa-3x text-primary mb-3"></i>
                                        <h4 className="mb-3">Award Winning</h4>
                                        <span>Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-12">
                                    <div className="border-start ps-4">
                                        <i className="fa fa-users fa-3x text-primary mb-3"></i>
                                        <h4 className="mb-3">Dedicated Team</h4>
                                        <span>Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Section End */}

            {/* Facts Section Start (with Parallax effect) */}
            <div className="container-fluid facts my-5 py-5 parallax" data-image-src={carouselImage} >
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Happy Clients</span>
                        </div>
                        <div className="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Garden Completed</span>
                        </div>
                        <div className="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Dedicated Staff</span>
                        </div>
                        <div className="col-sm-6 col-lg-3 text-center wow fadeIn" data-wow-delay="0.7s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Awards Achieved</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Facts Section End */}
        </div>
    );
};

export default AboutSeg;
