import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ title, breadcrumbs }) => {
    return (
        <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container text-center py-5">
                <h1 className="display-3 text-white mb-4 animated slideInDown">{title}</h1>
                <nav aria-label="breadcrumb" className="animated slideInDown">
                    <ol className="breadcrumb justify-content-center mb-0">
                        {breadcrumbs.map((crumb, index) => (
                            <li
                                key={index}
                                className={`breadcrumb-item ${crumb.active ? 'active' : ''}`}
                                aria-current={crumb.active ? 'page' : undefined}
                            >
                                {crumb.active ? (
                                    crumb.label
                                ) : (
                                    <Link to={crumb.path}>{crumb.label}</Link>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumb;
