// src/pages/About.js
import React from 'react';
import Members from './About/Members';
import Breadcrumb from '../components/Breadcrumb';
import AboutSeg from './About/AboutSeg';

const About = () => {
    const breadcrumbs = [
        { label: 'Home', path: '/', active: false },
        // { label: 'Pages', path: '/pages', active: false },
        { label: 'About', path: '/about', active: true },
    ];
    return (
        <div>
            <Breadcrumb title="About Us" breadcrumbs={breadcrumbs} />
            <AboutSeg />
            <Members />
        </div>
    );
};

export default About;
