import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/Navbar.css'; // Add custom styles for the drawer

const Navbar = () => {
    const location = useLocation();
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
                <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                    <h1 className="m-0">{isMobile ? 'SEG' : 'Sumadhura Eden Garden'}</h1>
                </Link>
                <button type="button" className="navbar-toggler me-4" onClick={toggleDrawer}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse d-none d-lg-flex" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
                        <Link to="/about" className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About</Link>
                        <Link to="/facilities" className={`nav-item nav-link ${location.pathname === '/facilities' ? 'active' : ''}`}>Facilities</Link>
                        <Link to="/events" className={`nav-item nav-link ${location.pathname === '/events' ? 'active' : ''}`}>Events</Link>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Guidelines</a>
                            <div className="dropdown-menu bg-light m-0">
                                <Link to="/features" className={`dropdown-item ${location.pathname === '/features' ? 'active' : ''}`}>Features</Link>
                                <Link to="/quote" className={`dropdown-item ${location.pathname === '/quote' ? 'active' : ''}`}>Free Quote</Link>
                                <Link to="/team" className={`dropdown-item ${location.pathname === '/team' ? 'active' : ''}`}>Our Team</Link>
                                <Link to="/testimonials" className={`dropdown-item ${location.pathname === '/testimonials' ? 'active' : ''}`}>Testimonial</Link>
                                <Link to="/404" className={`dropdown-item ${location.pathname === '/404' ? 'active' : ''}`}>404 Page</Link>
                            </div>
                        </div>
                        <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>
                    </div>
                    <a href="#" className="btn btn-primary py-4 px-lg-4 rounded-0 d-none d-lg-block">
                        Enquiry <i className="fa fa-arrow-right ms-3"></i>
                    </a>
                </div>
            </nav>

            {/* Mobile Drawer */}
            <div className={`mobile-drawer ${isDrawerOpen ? 'open' : ''}`}>
                <div className="drawer-content">
                    <button className="close-drawer" onClick={toggleDrawer}>X</button>
                    <Link to="/" className="drawer-link" onClick={toggleDrawer}>Home</Link>
                    <Link to="/about" className="drawer-link" onClick={toggleDrawer}>About</Link>
                    <Link to="/facilities" className="drawer-link" onClick={toggleDrawer}>Facilities</Link>
                    <Link to="/events" className="drawer-link" onClick={toggleDrawer}>Events</Link>
                    <Link to="/members" className="drawer-link" onClick={toggleDrawer}>Members</Link>
                    <Link to="/quote" className="drawer-link" onClick={toggleDrawer}>Free Quote</Link>
                    <Link to="/members" className="drawer-link" onClick={toggleDrawer}>SEG Members</Link>
                    <Link to="/contact" className="drawer-link" onClick={toggleDrawer}>Contact</Link>
                </div>
            </div>
            {/* Overlay when drawer is open */}
            {isDrawerOpen && <div className="drawer-overlay" onClick={toggleDrawer}></div>}
        </>
    );
};

export default Navbar;

// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Navbar = () => {
//     const location = useLocation();

//     return (
//         <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
//             <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
//                 <h1 className="m-0">Sumadhura Eden Garden</h1>
//             </Link>
//             <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
//                 <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="collapse navbar-collapse" id="navbarCollapse">
//                 <div className="navbar-nav ms-auto p-4 p-lg-0">
//                     <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
//                     <Link to="/about" className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About</Link>
//                     <Link to="/services" className={`nav-item nav-link ${location.pathname === '/services' ? 'active' : ''}`}>Services</Link>
//                     <Link to="/projects" className={`nav-item nav-link ${location.pathname === '/projects' ? 'active' : ''}`}>Projects</Link>
//                     <div className="nav-item dropdown">
//                         <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
//                         <div className="dropdown-menu bg-light m-0">
//                             <Link to="/features" className={`dropdown-item ${location.pathname === '/features' ? 'active' : ''}`}>Features</Link>
//                             <Link to="/quote" className={`dropdown-item ${location.pathname === '/quote' ? 'active' : ''}`}>Free Quote</Link>
//                             <Link to="/team" className={`dropdown-item ${location.pathname === '/team' ? 'active' : ''}`}>Our Team</Link>
//                             <Link to="/testimonials" className={`dropdown-item ${location.pathname === '/testimonials' ? 'active' : ''}`}>Testimonial</Link>
//                             <Link to="/404" className={`dropdown-item ${location.pathname === '/404' ? 'active' : ''}`}>404 Page</Link>
//                         </div>
//                     </div>
//                     <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>
//                 </div>
//                 <a href="#" className="btn btn-primary py-4 px-lg-4 rounded-0 d-none d-lg-block">
//                     Get A Quote <i className="fa fa-arrow-right ms-3"></i>
//                 </a>
//             </div>
//         </nav>
//     );
// };

// export default Navbar;
