import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import EventsSeg from './Events/EventsSeg';

const Events = () => {
    const breadcrumbs = [
        { label: 'Home', path: '/', active: false },
        { label: 'Events', path: '/events', active: true },
    ];

    return (
        <div>
            <Breadcrumb title="Events and Galleries" breadcrumbs={breadcrumbs} />
            <EventsSeg />
        </div >
    );
};

export default Events;
