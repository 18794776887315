import React from 'react';

const teamMembers = [
    {
        name: 'Doris Jordan',
        role: 'Landscape Designer',
        imgSrc: '/images/team-1.jpg',
        socialLinks: {
            facebook: 'https://www.facebook.com/SGedengarden/',
            twitter: '#',
            instagram: '#',
        },
    },
    {
        name: 'Johnny Ramirez',
        role: 'Garden Designer',
        imgSrc: '/images/team-2.jpg',
        socialLinks: {
            facebook: 'https://www.facebook.com/SGedengarden/',
            twitter: '#',
            instagram: '#',
        },
    },
    {
        name: 'Diana Wagner',
        role: 'Senior Sumadhura Eden Garden',
        imgSrc: '/images/team-3.jpg',
        socialLinks: {
            facebook: 'https://www.facebook.com/SGedengarden/',
            twitter: '#',
            instagram: '#',
        },
    },
];

const TeamMember = ({ name, role, imgSrc, socialLinks }) => (
    <div className="col-lg-4 col-md-6 wow fadeInUp">
        <div className="team-item rounded">
            <img className="img-fluid" src={imgSrc} alt={name} />
            <div className="team-text">
                <h4 className="mb-0">{name}</h4>
                <p className="text-primary">{role}</p>
                <div className="team-social d-flex">
                    {socialLinks.facebook && (
                        <a className="btn btn-square rounded-circle me-2" href={socialLinks.facebook}>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                    )}
                    {socialLinks.twitter && (
                        <a className="btn btn-square rounded-circle me-2" href={socialLinks.twitter}>
                            <i className="fab fa-twitter"></i>
                        </a>
                    )}
                    {socialLinks.instagram && (
                        <a className="btn btn-square rounded-circle me-2" href={socialLinks.instagram}>
                            <i className="fab fa-instagram"></i>
                        </a>
                    )}
                </div>
            </div>
        </div>
    </div>
);

const Members = () => (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                <p className="fs-5 fw-bold text-primary">Our Team</p>
                <h1 className="display-5 mb-5">Dedicated & Experienced Team Members</h1>
            </div>
            <div className="row g-4">
                {teamMembers.map((member, index) => (
                    <TeamMember
                        key={index}
                        name={member.name}
                        role={member.role}
                        imgSrc={member.imgSrc}
                        socialLinks={member.socialLinks}
                    />
                ))}
            </div>
        </div>
    </div>
);

export default Members;
