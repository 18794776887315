import React, { useEffect, useState } from 'react';

const EventsSeg = () => {
    // Store the projects and the active filter in the component's state
    const [projects] = useState([
        {
            id: 1,
            category: 'first',
            title: 'Landscaping',
            image: '/images/service-1.jpg',
            delay: '0.1s'
        },
        {
            id: 2,
            category: 'second',
            title: 'Pruning Plants',
            image: '/images/service-2.jpg',
            delay: '0.3s'
        },
        {
            id: 3,
            category: 'first',
            title: 'Irrigation & Drainage',
            image: '/images/service-3.jpg',
            delay: '0.5s'
        },
        {
            id: 4,
            category: 'second',
            title: 'Garden Maintenance',
            image: '/images/service-4.jpg',
            delay: '0.1s'
        },
        {
            id: 5,
            category: 'first',
            title: 'Green Technology',
            image: '/images/service-5.jpg',
            delay: '0.3s'
        },
        {
            id: 6,
            category: 'second',
            title: 'Urban Gardening',
            image: '/images/service-6.jpg',
            delay: '0.5s'
        }
    ]);

    // State for the currently selected filter (default is 'all')
    const [filter, setFilter] = useState('all');

    // Function to handle filter change
    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    // Filter the projects based on the current filter
    const filteredProjects = projects.filter((project) => {
        if (filter === 'all') return true;
        return project.category === filter;
    });

    return (
        <div className="container-xxl py-5">
            <div className="container">
                {/* Header Section */}
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="fs-5 fw-bold text-primary">Our Events</p>
                    <h1 className="display-5 mb-5">Some Of Our Wonderful Events</h1>
                </div>

                {/* Portfolio Filters */}
                <div className="row wow fadeInUp" data-wow-delay="0.3s">
                    <div className="col-12 text-center">
                        <ul className="list-inline rounded mb-5" id="portfolio-flters">
                            <li
                                className={`mx-2 ${filter === 'all' ? 'active' : ''}`}
                                onClick={() => handleFilterChange('all')}
                                style={{ cursor: 'pointer' }}
                            >
                                All
                            </li>
                            <li
                                className={`mx-2 ${filter === 'first' ? 'active' : ''}`}
                                onClick={() => handleFilterChange('first')}
                                style={{ cursor: 'pointer' }}
                            >
                                Complete Events
                            </li>
                            <li
                                className={`mx-2 ${filter === 'second' ? 'active' : ''}`}
                                onClick={() => handleFilterChange('second')}
                                style={{ cursor: 'pointer' }}
                            >
                                Ongoing Events
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Dynamically Render Filtered Projects */}
                <div className="row g-4 portfolio-container">
                    {filteredProjects.map((project) => (
                        <div key={project.id} className={`col-lg-4 col-md-6 portfolio-item ${project.category} wow fadeInUp`} data-wow-delay={project.delay}>
                            <div className="portfolio-inner rounded">
                                <img className="img-fluid" src={project.image} alt={project.title} />
                                <div className="portfolio-text">
                                    <h4 className="text-white mb-4">{project.title}</h4>
                                    <div className="d-flex">
                                        <a className="btn btn-lg-square rounded-circle mx-2" href={project.image} data-lightbox="portfolio">
                                            <i className="fa fa-eye"></i>
                                        </a>
                                        <a className="btn btn-lg-square rounded-circle mx-2" href="#">
                                            <i className="fa fa-link"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EventsSeg;
